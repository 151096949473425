<template>
<div>
  <div v-show="birthdayStatus" class="content">
    <img class="people-pic" src="../assets/people.png" alt="" />
    <div>
      <p class="staff-name" :style="calculateSize(info.name)">{{info.name}}</p>
      <img class="staff-img" :src="info.halfPhoto" />
    </div>

    <ul class="balloon" id="peopleList">
        <li v-for="(item, index) in liParamss" :key="index" :style="{ left: item.left, animationDuration: item.durTime, webkitAnimationDuration: item.durTime}" :class="item.cls" :data-index="index" @webkitAnimationEnd="removeBDom">
          <a href='javascript:;'>
            <i :style="{ transform: item.transforms, webkitTransform: item.transforms}"></i>
          </a>
        </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: 'birthday',
  data() {
    return {
      liParamss: [],
      info:{
        name:'',
        halfPhoto:''
      },
      birthdayStatus: false,
      timer:''
    }
  },
  methods: {
    init(data) {
      this.birthdayStatus = true;
      this.info = data;
      this.balloon()
    },
    balloon() {
      let win = document.documentElement.clientWidth || document.body.clientWidth
      let left = parseInt(Math.random() * (win - 50)) * 1.2
      let rotate = (parseInt(Math.random() * (45 - (-45)) - 45)) + 'deg'
      let scales = (Math.random() * (12 - 8 + 1) + 8) * 0.08 // 图片尺寸
      let durTime = (Math.random() * (2.5 - 1.2 + 1) + 1.2) + 's' // 时间  1.2和1.2这个数值保持一样
      this.liParamss.push({
        left: left + 'px',
        cls: 'move_1',
        transforms: 'rotate(' + rotate + ') scale(' + scales + ')',
        durTime: durTime
      })
      this.timer = setTimeout(() => {
        this.balloon()
      }, 800)
    },
    removeBDom(e) {
      let target = e.currentTarget
      document.querySelector('#peopleList').removeChild(target)
    },
    clearBallon() {
      clearTimeout(this.timer)
      this.birthdayStatus = false
    },
    getByteLen(str) {
      var len = 0;
      for (var i=0; i<str.length; i++) {
        var c = str.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
          len++;
        }
        else {
          len+=2;
        }
      }
      return len;
    },
    calculateSize(text) {
      let length = this.getByteLen(text)
      if (length <= 6) {
        return `font-size: ${54 / 19.2}vw`
      } else if (length <= 8) {
        return `font-size: ${40 / 19.2}vw`
      } else if (length <= 10) {
        return `font-size: ${32 / 19.2}vw`
      } else if (length <= 12) {
        return `font-size: ${24 / 19.2}vw`
      } else if (length < 18) {
        return `font-size: ${18 / 19.2}vw`
      } else {
        return `font-size: ${14 / 19.2}vw`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.audio {
  height: 40px;
  position: absolute;
  left: 40px;
  top: 20px;
}

.content {
  width:100%;
  height:100%;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat; //不重复
  background-size: 100% 100%;
  padding: 229px 191px;
  box-sizing: border-box;
  position:absolute;
  top:0;
  z-index:10;

  .people-pic {
    width: 1538px;
  }

  .staff-name {
    text-align: center;
    height: 63px;
    line-height: 63px;
    width: 320px;
    font-size: 50px;
    font-weight: 400;
    color: #BF051A;
    position: absolute;
    display: inline-block;
    top: 500px;
    left: 605px;
  }

  .staff-img {
    position: absolute;
    top: 254px;
    left: 1055px;
    width: 427px;
  }
}

.balloon {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 99;
  i {
    width: 510px;
    height: 579px;
    display: block;
    background-size: 100%;
    background: url('../assets/balloon.png') no-repeat;
  }

  li {
    position: absolute;
    animation: all 3s linear;
    top: -228px;
    z-index: 10;

    &.move_1 {
      -webkit-animation: balloon_move 10s linear 1 forwards;
      animation: balloon_move 10s linear 1 forwards;
    }
  }

  a {
    display: block;
  }
}

@keyframes balloon_move {
  0% {
    -webkit-transform: translateY(140vh);
    transform: translateY(140vh);
  }

  100% {
    -webkit-transform: translateY(-60vh);
    transform: translateY(-60vh);
  }
}
</style>
