<template>
  <div class="content">
    <div>
      <span class="audioBtn"  @click="palyHelpAudio">
        <img class="audioImg" ref="audioImg" src="../assets/mute.png" />
      </span>
      <audio ref="audio" :src="audioSrc" controls hidden></audio>
      <Swiper :banners="banners" ref="swiper" v-if="loading" :options="options"></Swiper>
      <birthday ref="birthday"></birthday>
      <entry ref="entry"></entry>
      <redEnvelopes ref="redEnvelopes"></redEnvelopes>
      <div class="popover" v-if="this.versionError">
        <p>检测到新版本，请更新到最新版本!</p>
        <div @click="reload">
          <span>立即更新！</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from '../components/swiper';
import birthday from '../components/birthday';
import entry from '../components/entry';
import redEnvelopes from "../components/redEnvelopes";
import {audioDataFormate} from '../util/index'

export default {
  data() {
    return {
      playStatus: false,
      audioSrc:require("../assets/help.mp3"),
      audioArr:[],
      loading: false,
      banners:['latestDeal'],
      options:[],
      info:{},
      playType: 0,
      playing: false,
      ws:'',
      socketStaus: false,
      deviceId:localStorage.getItem('DEVICEID'),
      version:localStorage.getItem('VERSION'),
      newVersion:'',
      versionError: false,
      dateTips:{}
    }
  },
  components:{
    Swiper,
    birthday,
    entry,
    redEnvelopes
  },
  provide () {
    return {
      info: this.info,
      getCompanyInfo: () => this.info,
    }
  },
  mounted() {
    let audio = this.$refs.audio;
    audio.addEventListener('ended', ()=> {
      this.playing = false;
      switch(this.playType){
        case 1:
          this.$refs.birthday.clearBallon()
          break;
        case 2:
          this.$refs.entry.clearBallon()
          break;
        case 3:
          // 判断播放的是否为合成语音，还是订单语音；
          this.$refs.swiper.swiper.autoplay = true;
          break;
      }

      this.audioArr.shift();
      // 重新播放语音;
      if(this.audioArr.length > 0) {
        this.playAudio(this.audioArr);
      }
    })

    audio.addEventListener('playing',()=> {
      this.playing = true;
    })

     audio.addEventListener('error', ()=> { //播放失败后，20s清空动画

       // 播放失败
       this.playing = true;
       setTimeout(()=> {
         switch(this.playType){
           case 1:
             this.$refs.birthday.clearBallon()
             break;
           case 2:
             this.$refs.entry.clearBallon()
             break;
           case 3:
             // 判断播放的是否为合成语音，还是订单语音；
             this.$refs.swiper.swiper.autoplay = true;
             break;
         }
         // 结束动画
         this.playing = false;
         this.audioSrc = ''
         this.audioArr.shift();
         this.playAudio(this.audioArr);
       }, 20000)
    });

    window.addEventListener('beforeunload', () => {
      this.ws.close()
    });
    this.palyHelpAudio()
     // 初始化socket
    this.init();
  },
  methods:{
    async init() {
      let options = await this.$http.get('blade-screen/screenOffice/rankList');
      this.options = options.data;
      let info = await this.$http.get('blade-screen/personalization/detail');
      this.info = info.data;
      if(!localStorage.getItem('DEVICEID')) {
        localStorage.setItem('DEVICEID', info.data.deviceId)
        this.deviceId = info.data.deviceId;
      }
      let list = this.info.isShowList;
      if(list.includes('weekNumShow')) {
        this.banners.push('quantityRanking')
      }
      if(list.includes('weekTotalShow')) {
        this.banners.push('numberRanking')
      }
      if(list.includes('monthNumShow')) {
        this.banners.push('monthQuantityRanking')
      }
      if(list.includes('monthTotalShow')) {
        this.banners.push('monthNumberRanking')
      }
      if(list.includes('monthPerShow')) {
        this.banners.push('completeness')
      }
      if(list.includes('timeShow')) {
        this.banners.push('countryTime')
      }
      this.loading = true;
      this.$nextTick(()=> {
        this.$refs.swiper.init(this.info.stayTime);
      })
    },
    initSocket() {
        if(!this.socketStaus) {
            let tenantId =localStorage.getItem('TENANTID');
            this.ws = new WebSocket(`wss://${process.env.VUE_APP_WSURL}${tenantId}/${this.deviceId}`);
            this.ws.onopen = this.socketOpen;
            this.ws.onmessage = this.socketMessgae;
            this.ws.onclose = this.socketClose;
        }
    },
    socketOpen() {
      this.socketStaus = true;
    },
    socketMessgae(res) {
      /**
       * type 类型为4时，为修改个性化设置，需要进行数据重新请求，并且过滤，不让其进入播报
       */
      let list = JSON.parse(res.data);
      list.forEach(item => {
        if(item.type == 4) {
          this.getCompanyData()
        }
        if(item.type == 5) {
          // 检查页面是否更新过
         this.newVersion = item.version;
          if(this.newVersion != this.version) {
            this.versionError = true;
          }
        }
      })

      let result = list.filter(item => {
        return item.type != 4 && item.type != 5
      })

      this.audioArr = this.audioArr.concat(audioDataFormate(result));
      if(!this.playing) {
        if(this.audioArr.length > 0) {
          this.playing = true;
          this.playAudio(this.audioArr)
        }
      }
    },
    socketClose() {
      this.socketStaus = false;
      // setTimeout(()=> {
      //   this.initSocket()
      // }, 3000)
    },
    palyHelpAudio() { // 唤醒语音，主动连接socket;
      this.audioSrc = require("../assets/help.mp3");
      this.$refs.audio.play();
      this.$refs.audioImg.src = require("../assets/vocal.png");
      this.initSocket();
    },
    playAudio(value) {
      // 播放语音，
      this.playing = true;
      this.playType = value[0].type;
      this.audioSrc = value[0].audioUrl;
      switch(value[0].type){
        case 1:
          // 切换生日组件，并且触发礼物动画，播放生日音乐，如果没音乐，动画在1分钟后停止，并隐藏组件;
          this.$refs.birthday.init(value[0])
          break;
        case 2:
          // 切换入职组件，并且触发礼物动画， 播放入职音乐，如果没音乐，动画在1分钟后停止，并隐藏组件;
          this.$refs.entry.init(value[0])
          break;
        case 3:
          // 回到第一页，暂停轮播，并且触发红包雨动画，播放订单音乐,并且请求数据更新;
          this.$refs.swiper.changeSwiper();
          this.$refs.redEnvelopes.startRedPacket();
          this.getRankingData();
          break;
      }

      setTimeout(()=> {
        this.$refs.audio.play();
      }, 2000)
    },
    getRankingData() {
      this.$http.get('blade-screen/screenOffice/rankList').then(res => {
        let result = res.data;
        this.options = result;
      })
    },
    getCompanyData(){
      this.$http.get('blade-screen/personalization/detail').then(res => {
      this.info = res.data;
      let list = res.data.isShowList;
      if(list.includes('weekNumShow')) {
        this.banners.push('quantityRanking')
      }
      if(list.includes('weekTotalShow')) {
        this.banners.push('numberRanking')
      }
      if(list.includes('monthNumShow')) {
        this.banners.push('monthQuantityRanking')
      }
      if(list.includes('monthTotalShow')) {
        this.banners.push('monthNumberRanking')
      }
      if(list.includes('monthPerShow')) {
        this.banners.push('completeness')
      }
      if(list.includes('timeShow')) {
        this.banners.push('countryTime')
      }
        this.$nextTick(()=> {
        this.$refs.swiper.init(this.info.stayTime);
      })
      })
    },
    reload() {
      // 刷新页面，并更换localStorage
      localStorage.setItem('VERSION', this.newVersion);
      this.versionError = false;
      window.location.reload();
    }
  },
  beforeDestroy() {
      if(this.socketStaus) {
          this.ws.close()
      }
  }
}
</script>

<style scoped lang="scss">
  .content{
    position: relative;
  }
  .playing{
    width:50px;
    height:50px;
    border-radius:50%
  }
  .audioBtn{
    position: fixed;
    right: 0;
    top: 0;
    width: 80px;
    cursor: pointer;
    z-index: 11;
    .audioImg{
      width: 100%;
      cursor: pointer;
    }
  }
  .popover{
    position: absolute;
    right: 20px;
    background: #FFF;
    /*width: 160px;*/
    min-width: 140px;
    border: 1px solid #EBEEF5;
    padding: 12px;
    z-index: 2000;
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    word-break: break-all;
    bottom:60px;
    font-weight: 600;
    div{
      text-align: right;
      cursor: pointer;
      color: #409eff;
      line-height: 30px;
    }
  }
</style>
