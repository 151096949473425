<template>
    <div class="content">
        <div class="top">
          <img class="logo" :src="reactiveInfo.logo">
          <span class="company-name">{{reactiveInfo.title}}</span>
          <img class="top-rate" src="../assets/completionRate.png" />
        </div>
      <div class="bottom">
        <div id="myChart"></div>
      </div>
    </div>
</template>

<script>
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/bar')
  export default {
    name: 'completeness',
    props:['options'],
      inject: [ "info", "getCompanyInfo"],
      data() {
      return {
        fontSize: 12,
        arr: [],
        nameArr: []
      }
    },
      computed:{
          reactiveInfo () {
              return this.getCompanyInfo()
          }
      },
    mounted() {
      const e = document.body.offsetWidth
      this.fontSize = e / 1082 * 9
      this.drawLine()

    },
    methods:{
      drawLine() {
          this.arr = this.options.monthOrderAchieveRate.map(item=> {
              return item.achieveRate
          });

          this.nameArr = this.options.monthOrderAchieveRate.map(item=> {
              return item.name
          });
          // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById('myChart'))
        let colorList = ['#C51E30', '#B33C13', '#FF5A34', '#FF9C40', '#FF7B00', '#FFD41D', '#1EBEA4', '#168F7A', '#117362', '#168F7A', '#1EBEA4', '#FFD41D', '#FF7B00', '#FF9C40', '#FF5A34', '#B33C13', '#C51E30',
  '#B33C13', '#FF5A34', '#FF9C40', '#FF7B00', '#FFD41D', '#1EBEA4', '#168F7A', '#117362', '#168F7A', '#1EBEA4', '#FFD41D', '#FF7B00', '#FF9C40', '#FF5A34', '#B33C13', '#C51E30']
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{c}%'
          },
          grid: {
            left: '1%',
            right: '3%',
            bottom: '2%',
            containLabel: true
          },
          itemStyle: {
            barBorderRadius: 10
          },
          xAxis: {
            type: 'category',
            inverse: true,
            data: this.nameArr,
            axisLabel: {
              margin: 20,
              interval: 0,
              textStyle: {
                fontWeight: 700,
                fontSize: this.fontSize + 2,
                color: 'black'
              }
            }
          },
          yAxis: [{
            type: 'value',
            show: false
          }],
          series: [{
            type: 'bar',
            itemStyle: {
              normal: {
                color: function(params) {
                  // build a color map as your need.
                  return colorList[params.dataIndex]
                },
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c}%',
                  fontSize: this.fontSize + 2,
                  color: 'black',
                  fontWeight: 700
                }
              }
            },
            data: this.arr
          }]
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .content{
    padding: 33px 79px;
    .top{
      position: relative;
      .logo{
        max-height: 50px;
        position: absolute;
        top: 10px;
        left: 10px;
      }
      .company-name{
        position: absolute;
        top: 90px;
        left: 30px;
        width: 230px;
        height: 75px;
        font-size: 36px;
        font-weight: 800;
        color: #FFFFFF;
      }
      .top-rate{
          height: 252px;
      }
    }
    .bottom{
      width: 1756px;
      height: 600px;
      background: #FFFFFF;
      border: 15px solid #BF051A;
      border-radius: 10px;
      #myChart{
        height: 535px;
        width:100%
      }
    }
  }
</style>
