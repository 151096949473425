<template>
  <div class="login">
    <div class="login-title">
      外贸业务员销售排行榜大屏展示</div>
    <div class="login-content">
      <div>
        <p class="lable">企业ID：</p>
        <input class="zl-input" v-model="tenantId"/>
        <p class="lable">账号：</p>
        <input class="zl-input" v-model="username"/>
        <p class="lable">密码：</p>
        <input type="password" v-model="password" class="zl-input" @keydown.enter="submit"/>
        <button class="zl-button" @click="submit()">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      tenantId:localStorage.getItem('TENANTID'),
      username:'',
      password:''
    }
  },
  mounted(){
    if(this.$route.query.token && this.$route.query.tenantId){
      // 含有token, 直接跳转到首页
      localStorage.setItem('TOKEN', this.$route.query.token);
      localStorage.setItem('TENANTID', this.$route.query.tenantId);
      this.$router.push('/office')
    }
  },
  methods:{
    submit() {
      let params = {
        tenantId: this.tenantId,
        username: this.username,
        password: this.password
      }

      this.$http.loginPost('blade-auth/oauth/token', params).then(res => {
        if(res.access_token) {
          localStorage.setItem('TOKEN', res.access_token);
          localStorage.setItem('TENANTID', res.tenant_id);
          this.$router.push('/office')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login{
    background-color: #333;
    width: 100%;
    height: 100%;
    .login-title {
      font-size: 34px;
      text-align: center;
      padding: 5px 0;
      color: #fff;
    }
    .login-content{
      width: 340px;
      margin: 20px auto;
      background-color: white;
      padding: 30px;
      border-radius: 2px;
    }
  }

  .lable{
    margin-bottom: 10px;
    color: #7f8fa4;
    font-weight: 700;
    font-size: 14px;
  }
  .zl-input{
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    margin-bottom: 20px;
    padding: 0 15px;
    box-sizing: border-box;
    line-height: 40px;
  }
  .zl-input:focus{
    border: 1px solid #67c23a;
  }
  .zl-button{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #409eff;
    color: #fff;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    width: 100%;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    background-color: #67c23a;
  }
</style>
