<template>
  <div class="box">
    <div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/zhongguo.png" alt="" class="National">
          <div>
            <span class="city">[中国]</span><br>
            <span class="city">北京时间(GMT +8)</span>
          </div>
        </div>
        <div class="time">
          {{china_time}}
          <span class="date">   {{china_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/meiguo.png" alt="" class="National">
          <div>
            <span class="city">[美国]</span><br>
            <span class="city">纽约时间(GMT -5)</span>
          </div>
        </div>
        <div class="time">
          {{newYork_time}}
          <span class="date"> {{newYork_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/riben.png" alt="" class="National">
          <div>
            <span class="city">[日本]</span><br>
            <span class="city">东京时间</span>
          </div>
        </div>
        <div class="time">
          {{tokyo_time}}
          <span class="date"> {{tokyo_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/faguo.png" alt="" class="National">
          <div>
            <span class="city">[法国]</span><br>
            <span class="city">巴黎时间</span>
          </div>
        </div>
        <div class="time">
          {{France_time}}
          <span class="date"> {{France_date}}</span>
        </div>
        </div></div>
        <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/deguo.png" alt="" class="National">
          <div>
            <span class="city">[德国]</span><br>
            <span class="city">柏林时间</span>
          </div>
        </div>
        <div class="time">
          {{bolin_time}}
          <span class="date"> {{bolin_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/hanguo.png" alt="" class="National">
          <div>
            <span class="city">[韩国]</span><br>
            <span class="city">首尔时间</span>
          </div>
        </div>
        <div class="time">
          {{tokyo_time}}
          <span class="date"> {{tokyo_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/helan.png" alt="" class="National">
          <div>
            <span class="city">[荷兰]</span><br>
            <span class="city">阿姆斯特丹时间</span>
          </div>
        </div>
        <div class="time">
          {{bolin_time}}
          <span class="date"> {{bolin_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/yidali.png" alt="" class="National">
          <div>
            <span class="city">[意大利]</span><br>
            <span class="city">罗马时间</span>
          </div>
        </div>
        <div class="time">
          {{bolin_time}}
          <span class="date"> {{bolin_date}}</span>
        </div>
        </div></div>
        <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/eluosi.png" alt="" class="National">
          <div>
            <span class="city">[俄罗斯]</span><br>
            <span class="city">莫斯科时间</span>
          </div>
        </div>
        <div class="time">
          {{Russia_time}}
          <span class="date"> {{Russia_date}}</span>
        </div>
        </div></div>
      <div class="col"><div class="grid-content bg-purple">
        <div class="National_box">
          <img src="../assets/country/yingguo.png" alt="" class="National">
          <div>
            <span class="city">[英国]</span><br>
            <span class="city">伦敦时间</span>
          </div>
        </div>
        <div class="time">
          {{lundon_time}}
          <span class="date"> {{lundon_date}}</span>
        </div>
        </div></div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    name:'countryTime',
    data() {
      return {
        china_time: '',
        china_date: '',
        germany_time: '',
        germany_date: '',
        newYork_time: '',
        newYork_date: '',
        tokyo_time: '',
        tokyo_date: '',
        France_time: '',
        France_date: '',
        Russia_time: '',
        Russia_date: '',
        lundon_date: '',
        lundon_time: '',
        bolin_date: '',
        bolin_time: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
          setInterval(()=> {
            this.china_time = this.formatTime().time;
            this.china_date = this.formatTime().date;

            // 纽约
            this.newYork_time = this.formatTime(this.computedTime(-5)).time;
            this.newYork_date = this.formatTime(this.computedTime(-5)).date;
            // 东京
            this.tokyo_time = this.formatTime(this.computedTime(9)).time;
            this.tokyo_date = this.formatTime(this.computedTime(9)).date;
            // 巴黎 柏林
            this.bolin_time = this.formatTime(this.computedTime(1)).time;
            this.bolin_date = this.formatTime(this.computedTime(1)).date;
            // France_time
            this.France_time = this.formatTime(this.computedTime(1)).time;
            this.France_date = this.formatTime(this.computedTime(1)).date;
            // 莫斯科
            this.Russia_time = this.formatTime(this.computedTime(1)).time;
            this.Russia_date = this.formatTime(this.computedTime(1)).date;
            // 伦敦
            this.lundon_time = this.formatTime(this.computedTime(1)).time;
            this.lundon_date = this.formatTime(this.computedTime(1)).date;
          }, 1000)
      },
      checkTime(num) {
        if(num < 10) {
          return '0' + num
        } else{
          return num
        }
      },
      formatTime(time = new Date()) {
        let h = this.checkTime(time.getHours());
        let m = this.checkTime(time.getMinutes());
        let s = this.checkTime(time.getSeconds());
        let day = this.checkTime(time.getDate());
        let month = this.checkTime(time.getMonth() + 1);
        return {
          time: h + ':' + m + ':' + s,
          date: `${month}/${day}`
        }
      },
      computedTime(offset) {
          var d=new Date();
          var localTime = d.getTime();
          var localOffset=d.getTimezoneOffset()*60000;
          var utc = localTime + localOffset;
          var korean= utc + (3600000*offset);
          var nd = new Date(korean);
          return nd;
      }
    }
  }
</script>

<style scoped lang="scss">
.box{
  padding: 20px 0;
  width:100%;
  height:100%;
  background: white;
  color: #282828;
}
.National{
  width: 100px;
  margin-right: 20px
}
.National_box{
  display: flex;
  align-items: center;
  height: 53%;
}
.city {
  font-size: 25px;
}
.date{
  line-height: 50px;
  font-size: 25px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 20px;
  justify-content: space-between;
}
.time{
  font-size: 50px;
  display: flex;
}
.day{
  font-size: 20px;
}
.grid-content{
  background: #f8f8f8;
  margin-bottom: 20px;
  height: 160px;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  padding: 0 30px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}
.clock{
  text-align: center;
}

.col{
  width:50%;
  display: inline-block;
}
</style>
