<template>
  <div class="content">
    <div class="top">
      <div class="top-left">
        <img class="logo" :src="reactiveInfo.logo"/>
        <h1 class="companyName" :style="calculateTitle(reactiveInfo.title)">{{reactiveInfo.title}}</h1>
        <p class="solgan" :style="calculateSlogan(reactiveInfo.slogan)">{{reactiveInfo.slogan}}</p>
        <p class="time">
          <span>{{dateTips.date1}}</span>
          <span>{{dateTips.date2}}</span>
          <span>{{dateTips.date3}}</span>
        </p>
        <p class="rate" v-if="reactiveInfo.currencyName !== '人民币'">实时汇率：1{{reactiveInfo.currencyName}}={{reactiveInfo.rateValue}}人民币</p>
        <p class="type">本周订单数量榜</p>
      </div>
      <div class="top-right">
        <div class="target">
          <p class="target-title">本周之星</p>
          <div class="target-box">
            <img class="staff-headerimg" :src="first.avatar" />
            <div class="target-bottom">
              <p class="staff-name">{{first.name}}</p>
              <span>订单数量</span>
              <p class="staff-count">{{first.totalCount}}单</p>
            </div>
          </div>
        </div>
        <div class="target">
          <p class="target-title">上周之星</p>
          <div class="target-box">
            <img class="staff-headerimg" :src="lastTime.avatar" />
            <div class="target-bottom">
              <p class="staff-name">{{lastTime.name}}</p>
              <span>订单数量</span>
              <p class="staff-count">{{lastTime.totalCount}}单</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <ul class="staff">
        <li class="item" v-for="(item,index) in last" :key="index">
          <div>
            <p>NO.{{index + 2}}</p>
            <p>{{item.name}}</p>
          </div>
          <img class="staff-img" :src="item.halfPhoto" />
          <div>
            <p>订单数量</p>
            <p>{{item.totalCount}}单</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {timeFormate} from '../util'
  export default {
    name: 'quantityRanking',
    props:['options'],
    inject: [ "info", "getCompanyInfo"],
    mounted() {
      this.initTime()
    },
    data() {
      return {
        dateTips:{}
      }
    },
    computed:{
      first() {
        return this.options.weekOrderCount.this[0] ? this.options.weekOrderCount.this[0] : {
          name:'员工名',
          totalCount:0,
          avatar:''
        }
      },
      last() {
        return this.options.weekOrderCount.this.filter((item, index)=> {
          if(index > 0) {
            return item
          }
        })
      },
      lastTime() {
        return this.options.weekOrderCount.last ? this.options.weekOrderCount.last : {
          name:'员工名',
          totalCount:0,
          avatar:''
        }
      },
      reactiveInfo () {
        return this.getCompanyInfo()
      }
    },
    methods:{
      initTime() {
        return setInterval(()=> {
          this.dateTips = timeFormate()
        }, 1000)
      },
      getByteLen(str) {
        var len = 0;
        for (var i=0; i<str.length; i++) {
          var c = str.charCodeAt(i);
          //单字节加1
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
            len++;
          }
          else {
            len+=2;
          }
        }
        return len;
      },
      calculateTitle(title) {
        let length = this.getByteLen(title)
        if (length < 18) {
          return `font-size: ${80 / 19.2}vw`
        } else if(length < 22){
          return `font-size: ${70 / 19.2}vw`
        } else {
          return `font-size: ${50 / 19.2}vw`
        }
      },
      calculateSlogan(slogan) {
        let length = this.getByteLen(slogan)
        if (length < 40) {
          return `font-size: ${50 / 19.2}vw`
        } else if(length < 42){
          return `font-size: ${40 / 19.2}vw`
        } else {
          return `font-size: ${30 / 19.2}vw`
        }
      }
    }
  }
</script>

<style  lang="scss" scoped>
  .content{
    padding: 42px;
    .top{
      overflow: hidden;
      .top-left{
        width: 820px;
        float: left;
        .logo{
          max-height: 101px;
          max-width:100%;
        }
        .companyName{
          margin-bottom: 24px;
          font-size: 68px;
          font-weight: bold;
          color: #BF051A;
        }
        .solgan{
          font-size: 50px;
          font-weight: 400;
          color: #BF051A;
        }
        .time{
          font-size: 41px;
          font-weight: 400;
          color: #BF051A;
          margin-top: 10px;
        }
        .rate{
          font-size: 41px;
          font-weight: bold;
          color: #BF051A;
          margin-top: 10px;
          line-height: 60px;
        }
        .type{
          font-size: 68px;
          font-weight: bold;
          color: #BF051A;
          line-height: 100px;
        }
      }

      .top-right{
        float: right;
        display: flex;
        justify-content: space-around;
        .target{
          margin: 0 67px;
          text-align: center;
          position: relative;
          .staff-headerimg{
            width: 200px;
            height: 200px;
            overflow: hidden;
            border-radius: 50%;
            position: absolute;
            left: 52px;
            top: 136px;
          }
        }
        .target-title{
          height: 35px;
          font-size: 36px;
          font-weight: bold;
          color: #BF051A;
          margin-bottom: 13px;
        }
       .target-box{
          width: 300px;
         height: 492px;
         background-image: url("https://initiranking-system.oss-cn-hangzhou.aliyuncs.com/headBlock.png");
         background-repeat: no-repeat;
         background-size: 100% 100%;
         text-align: center;
         color: white;

         .target-bottom{
           position: absolute;
           bottom: 30px;
           left: 0;
           text-align: center;
           width: 100%;
           span{
             display: inline-block;
             margin: 10px 0;
           }
         }

         .staff-name{
           height: 35px;
           font-size: 36px;
           font-weight: 400;
           color: #FFFFFF;
         }
         .staff-count{
            line-height: 50px;
            font-size: 58px;
           font-weight: 400;
           color: #FFFFFF;
         }
       }
      }
    }
    .bottom{
      .staff{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      .item{
        width: 24%;
        height: 120px;
        border: 6px solid #BF051A;
        margin: 24px 24px 24px 0;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
          font-size: 25px;
          font-weight: 400;
          color: #BF051A;
          margin: 6px 0;
        }
        .staff-img{
          width: 62px;
          height: 81px;
        }
      }
      .item:nth-child(4), .item:nth-child(8){
        margin-right: 0;
      }

    }
  }
</style>
