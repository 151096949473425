import Vue from 'vue'
import Router from 'vue-router';
import login from '../views/login.vue';
import office from '../views/office.vue';

Vue.use(Router)

const router = new Router(
  {
    routes:[
      {
        path: '/',
        redirect: '/login'
      },
      {
        path: '/login',
        component: login,
        name: 'login'
      },
      {
        path: '/office',
        component: office
      }
    ]
  }
)

export default router
