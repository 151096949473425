/**
 * http.js - 封装axios请求
 *  包括请求前后拦截，post封装，请求路径组装，Loading动画显示与隐藏
 *  api网关返回错误拦截
 *
 */
import axios from 'axios';
import md5 from 'js-md5';
import router from '../router'
import showToast from '../components/toast'

// 请求拦截 - 携带token头
axios.interceptors.request.use(config => {
  let token = localStorage.getItem("TOKEN");
  config.headers['Authorization'] = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0';
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  if (token) {
    config.headers['Blade-Auth'] = 'bearer ' + token
  }
  return config
}, error => {
  return Promise.reject(error)
});
//
axios.interceptors.response.use(response => {
  // 网关层判断 -接入网关层之后 模式
  if (!response.data.status) {
    return Promise.resolve(response.data);
  } else {
    if (response.data.code != 200) {
      showToast({
        text:response.data.msg,
        type:'wraning'
      })
      return Promise.reject(response);
    } else {
      //业务层错误信息提示
      if(response.data.sub_code != 'success'){
        showToast({
          text:response.data.msg,
          type:'wraning'
        })
        return Promise.reject(response);
      }
    }
  }
}, error => {
  if(error.response.status == 400) {
    showToast({
      text:'用户名或密码错误!',
      type:'wraning'
    })
  } else if(error.response.status == 401) {
    router.push('/login')
    showToast({
      text:'登录已过期，请重新登录!',
      type:'wraning'
    })
  }
  return Promise.reject(error.response);
});


export default {
  post(url, params) {
    const requestUrl = process.env.VUE_APP_URL + url;//组装请求地址
    return axios({
      method: 'post',
      url: requestUrl,
      data: params,
      timeout: 10000,
      headers: {
        'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  get(url, params) {
    const requestUrl = process.env.VUE_APP_URL + url;//组装请求地址
    return axios.get(requestUrl, params)
  },
  //专用于登录的请求方法
  loginPost(url, params) {
    const requestUrl = process.env.VUE_APP_URL;//组装请求地址;
    let paramsStr = `tenantId=${params.tenantId}&username=${params.username}&password=${md5(params.password)}&grant_type=password&scope=all`
    return axios({
      method: 'post',
      dataType: 'json',
      url: requestUrl + 'blade-auth/oauth/token?' + paramsStr,
      timeout: 10000,
      headers: {
        'Tenant-Id': params.tenantId,
        'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }
}

