
const  checkTime = (num)=> {
    if(num < 10) {
        return '0' + num
    } else{
        return num
    }
}

export const timeFormate = () =>{
    let time = new Date()
    let y = checkTime(time.getFullYear());
    let h = checkTime(time.getHours());
    let m = checkTime(time.getMinutes());
    let s = checkTime(time.getSeconds());
    let day = checkTime(time.getDate());
    let month = checkTime(time.getMonth() + 1);
    let weekday=["周日","周一","周二","周三","周四","周五","周六"];
    return {
        date1: `${y}年${month}月${day}日`,
        date2: weekday[time.getDay()],
        date3: `${h}:${m}:${s}`
    }
}

export const audioDataFormate = (data) =>{
    let arr = [];
    data.forEach(value => {
        if(value.audioUrl.length > 1) {
            arr.push({
                audioUrl:value.audioUrl[0],
                type:value.type,
                name: value.name,
                halfPhoto: value.halfPhoto
            })
            arr.push({
                audioUrl:value.audioUrl[1],
                type:0,
                name: value.name,
                halfPhoto: value.halfPhoto
            })
        } else {
            arr.push({
                audioUrl:value.audioUrl[0],
                type:value.type,
                name: value.name,
                halfPhoto: value.halfPhoto
            })
        }
    })
    return arr
}
