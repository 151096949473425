<template>
    <div class="stockLog">
        <!--红包模块-->
        <div class="ser_home">
            <ul class="red_packet" id="red_packet">
                <li v-for="(item, index) in liParams" :key="index" :style="{ left: item.left, animationDuration: item.durTime, webkitAnimationDuration: item.durTime, mozAnimationDuration: item.durTime, msAnimationDuration: item.durTime, oAnimationDuration:item.durTime}"
                    :class="item.cls" :data-index="index" @webkitAnimationEnd="removeDom">
                    <a href='javascript:;'>
                        <i :style="{ transform: item.transforms, webkitTransform: item.transforms, mozTransform:item.transforms,  msTransform:item.transforms, oTransform:item.transforms}"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        props: {
            duration: {
                type: Number,
                default: 20000
            }
        },
        data() {
            return {
                liParams: [],
                timer: null
            }
        },
        methods: {
            // 红包雨功能
            startRedPacket() {
                let win = document.documentElement.clientWidth || document.body.clientWidth
                // let win = 0
                let left = (parseInt(Math.random() * (win - 50)) * 1.2).toFixed(2)
                let rotate = (parseInt(Math.random() * (45 - (-45)) - 45)).toFixed(2) + 'deg'
                let scales = ((Math.random() * (12 - 8 + 1) + 8) * 0.08).toFixed(2)  // 图片尺寸
                let durTime = ((Math.random() * (2.5 - 1.2 + 1) + 1.2)).toFixed(2) + 's' // 时间  1.2和1.2这个数值保持一样
                this.liParams.push({left: left + 'px', cls: 'move_1', transforms: 'rotate(' + rotate + ') scale(' + scales + ')', durTime: durTime})
                this.timer = setTimeout(() => {
                    this.startRedPacket()
                }, 200)
                setTimeout(() => {
                    clearTimeout(this.timer)
                    return
                }, this.duration)
            },
            removeDom (e) {
                let target = e.currentTarget
                document.querySelector('#red_packet').removeChild(target)
            }
        }
    }
</script>

<style scoped lang="scss">
    .ser_home {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        .red_packet {
            display: block;
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            i {
                width: 175px;
                height: 228px;
                display: block;
                background: url('https://initiranking-test.oss-cn-hangzhou.aliyuncs.com/%E7%BA%A2%E5%8C%85.png') no-repeat;
                background-size: 100%
            }
            li {
                position: absolute;
                animation: all 3s linear;
                top:-228px;
                z-index: 10;
                &.move_1 {
                    display:inline-block;
                    -webkit-animation: aim_move 5s linear 1 normal;
                    -moz-animation: aim_move 5s linear 1 normal;
                    -ms-animation: aim_move 5s linear 1 normal;
                    -o-animation: aim_move 5s linear 1 normal;
                    animation: aim_move 5s linear 1 normal;
                }
            }
            a {
                display: block;
            }
        }

        @keyframes aim_move {
            0% {
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }
            100% {
                -webkit-transform: translateY(140vh);
                -moz-transform: translateY(140vh);
                -ms-transform: translateY(140vh);
                -o-transform: translateY(140vh);
                transform: translateY(140vh);
            }
        }
    }
</style>
