<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item,index) in banners" :key="index">
        <component v-bind:is="item" :options="options"></component>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
import latestDeal from './latestDeal.vue';
import quantityRanking from './quantityRanking.vue';
import numberRanking from './numberRanking.vue';
import monthQuantityRanking from './monthQuantityRanking.vue';
import monthNumberRanking from './monthNumberRanking.vue';
import completeness from './completeness.vue';
import countryTime from './time.vue';

export default {
  data() {
    return {
      swiper: '',
      stayTime: 10
    }
  },
  props:['banners', 'options'],
  components:{
    latestDeal,
    quantityRanking,
    numberRanking,
    monthQuantityRanking,
    monthNumberRanking,
    completeness,
    countryTime
  },
  methods:{
    init(stayTime) {
      // let _this = this;
      this.stayTime = stayTime;
      this.swiper = new Swiper('.swiper-container', {
        loop:true, //是否循环轮播
        autoplay: {
          delay: stayTime * 1000
        },
        disableOnInteraction: false,
        observer:true,
        observeParents:false,
        on: {
            slideChangeTransitionEnd() {
              this.slideToLoop(this.realIndex, 0, true);
            }
        }
      })
    },
    changeSwiper() {
      this.swiper.slideTo(1, 1000);
      setTimeout(()=> {
        this.init(this.stayTime)
      }, 21000)
    }
  }
}
</script>

<style scoped>
  .audioBtn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: black;
    position: absolute;
    right: 40px;
    top: 40px;
    color: white;
    text-align: center;
  }
</style>
