<template>
    <div class="content">
      <div class="top">
        <div class="top-left">
          <img class="logo" :src="reactiveInfo.logo"/>
          <h1 class="companyName" :style="calculateTitle(reactiveInfo.title)">{{reactiveInfo.title}}</h1>
          <p class="solgan" :style="calculateSlogan(reactiveInfo.slogan)">{{reactiveInfo.slogan}}</p>
          <p class="time">
            <span>{{dateTips.date1}}</span>
            <span>{{dateTips.date2}}</span>
            <span>{{dateTips.date3}}</span>
          </p>
          <p class="rate" v-if="reactiveInfo.currencyName !== '人民币'">实时汇率：1{{reactiveInfo.currencyName}}={{reactiveInfo.rateValue}}人民币</p>
        </div>
        <div class="top-right">
          <p class="target-title"></p>
          <div class="target-box">
            <div class="target-content">
              <p class="title1">实时业绩榜</p>
              <p class="title2">NO.1</p>
              <p class="title3" :style="calculateSize(first.name)">{{first.name}}</p>
              <p class="title4">{{timeAgo(first.ocreateTime)}}</p>
              <p class="title5">{{reactiveInfo.symbol}}{{Math.round(first.totalAmount)}}</p>
            </div>
            <img class="top-img" :src="first.halfPhoto"/>
          </div>
        </div>
      </div>
      <div class="bottom">
        <ul class="staff">
          <li class="item" v-for="(item,index) in last" :key="index">
            <div>
              <p>NO.{{index + 2}}</p>
              <p>{{item.name}}</p>
            </div>
            <img class="staff-img" :src="item.halfPhoto" />
            <div>
              <p>{{timeAgo(item.ocreateTime)}}</p>
              <p>{{reactiveInfo.symbol}}{{Math.round(item.totalAmount)}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import {timeFormate} from '../util'
  export default {
    name: 'latesDeal',
    props:['options'],
    computed:{
      first() {
        return this.options.latestOrderAmount[0] ? this.options.latestOrderAmount[0] : {
          name:'员工名',
          totalAmount:0,
          halfPhoto:''
        }
      },
      last() {
        return this.options.latestOrderAmount.filter((item, index)=> {
          if(index > 0) {
            return item
          }
        })
      },
      reactiveInfo () {
        return this.getCompanyInfo()
      }
    },
    data() {
      return {
        dateTips:{}
      }
    },
    inject: [ "info", "getCompanyInfo"],
    mounted() {
      setInterval(()=> {
      this.dateTips = timeFormate();
    }, 1000)
    },
    methods:{
      timeAgo(dateTimeStamp) {
        let minute = 1000 * 60;
        let hour = minute * 60;
        let day = hour * 24;
        let month = day * 30;
        if (dateTimeStamp == undefined) {
          return '--';
        } else {
          var sTime = new Date(dateTimeStamp).getTime();//把时间pretime的值转为时间戳
          var now = new Date().getTime();//获取当前时间的时间戳
          var diffValue = now - sTime;
          var monthC = diffValue / month;
          var weekC = diffValue / (7 * day);
          var dayC = diffValue / day;
          var hourC = diffValue / hour;
          var minC = diffValue / minute;
          if (monthC >= 1) {
            return parseInt(monthC) + "个月前"
          } else if (weekC >= 1) {
            return parseInt(weekC) + "周前"
          } else if (dayC >= 1) {
            return parseInt(dayC) + "天前"
          } else if (hourC >= 1) {
            return parseInt(hourC) + "个小时前"
          } else if (minC >= 1) {
            return parseInt(minC) + "分钟前"
          } else {
            return "刚刚"
          }
        }
      },
      getByteLen(str) {
        var len = 0;
        for (var i=0; i<str.length; i++) {
          var c = str.charCodeAt(i);
          //单字节加1
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
            len++;
          }
          else {
            len+=2;
          }
        }
        return len;
      },
      calculateSize(text) {
        let length = this.getByteLen(text)
        if (length <= 6) {
          return `font-size: ${34 / 19.2}vw`
        } else if (length <= 8) {
          return `font-size: ${32 / 19.2}vw`
        } else if (length <= 10) {
          return `font-size: ${30 / 19.2}vw`
        } else if (length <= 12) {
          return `font-size: ${28 / 19.2}vw`
        } else if (length < 18) {
          return `font-size: ${22 / 19.2}vw`
        } else {
          return `font-size: ${20 / 19.2}vw`
        }
      },
      calculateTitle(title) {
        let length = this.getByteLen(title)
        if (length < 18) {
          return `font-size: ${80 / 19.2}vw`
        } else if(length < 22){
          return `font-size: ${70 / 19.2}vw`
        } else {
          return `font-size: ${50 / 19.2}vw`
        }
      },
      calculateSlogan(slogan) {
        let length = this.getByteLen(slogan)
        if (length < 40) {
          return `font-size: ${50 / 19.2}vw`
        } else if(length < 42){
          return `font-size: ${40 / 19.2}vw`
        } else {
          return `font-size: ${30 / 19.2}vw`
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .content{
    padding: 42px;
    .top{
      overflow: hidden;
      .top-left{
        width: 820px;
        float: left;
        .logo{
          max-height: 101px;
          max-width:100%;
        }
        .companyName{
          margin-bottom: 24px;
          height: 80px;
          font-size: 65px;
          font-weight: bold;
          color: #BF051A;
          line-height: 108px;
        }
        .solgan{
          height: 79px;
          font-size: 50px;
          font-weight: 400;
          color: #BF051A;
          line-height: 108px;
        }
        .time{
          font-size: 48px;
          font-weight: 400;
          color: #BF051A;
          line-height: 108px;
        }
        .rate{
          font-size: 48px;
          font-weight: bold;
          color: #BF051A;
          line-height: 108px;
        }
      }

      .top-right{
        float: right;
        .target-title{
          margin: 15px 0;
          height: 42px;
          font-size: 42px;
          font-weight: 400;
          color: #BF051A;
          text-align: center;
        }

        .target-box{
          width: 963px;
          height: 415px;
          background-image: url(https://initiranking-system.oss-cn-hangzhou.aliyuncs.com/realQueen.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          display: flex;

          .target-content{
            text-align: center;
            position: absolute;
            left: 170px;
            top: 45px;
            .title1{
              font-size: 61px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FEFEFE;
              line-height: 70px;
            }
            .title2{
              font-size: 57px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FEFEFE;
              line-height: 70px;
            }
            .title3{
              font-size: 35px;
              width:200px;
              display: inline-block;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #BF051A;
              line-height: 50px;
            }
            .title4{
              margin-top: 20px;
              font-size: 29px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
            }
            .title5{
              height: 73px;
              font-size: 75px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FEFEFE;
              line-height: 101px;
            }
          }

          .top-img{
            width: 286px;
            position: absolute;
            top: 15px;
            left: 514px;
          }
        }
      }
    }
    .bottom{
      .staff{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      .item{
        width: 24%;
        height: 120px;
        border: 6px solid #BF051A;
        margin: 24px 24px 24px 0;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
          font-size: 24px;
          font-weight: 400;
          color: #BF051A;
          margin: 6px 0;
        }
        .staff-img{
          width: 62px;
          height: 81px;
        }
      }

      .item:nth-child(4), .item:nth-child(8){
        margin-right: 0;
      }
    }
  }
</style>
